.menu-left-nest, #menu-showhide {
  background: #0000001a;
  border-radius: 3px;
}

#skin-select {
  height: auto;
  width: 250px;
  z-index: 1000;
  position: absolute;
  top: 20px;
}

#skin-select #toggle {
  color: #fffc;
  cursor: pointer;
  height: 30px;
  text-align: center;
  width: 30px;
  background: #0000004d;
  border-radius: 3px;
  font-size: 14px;
  line-height: 30px;
  display: block;
  position: absolute;
  top: -10px;
  right: 10px;
}

#skin-select #toggle:hover {
  background: #000c;
}

#skin-select #toggle.active {
  height: 30px;
  width: 30px;
  background-position: -5px -45px;
  border-radius: 3px;
  position: absolute;
  top: -10px;
  left: 210px;
}

#skin-select li {
  text-align: left;
  color: #fff;
  margin: 0;
  font-size: 20px;
  line-height: 0;
  list-style: none;
}

#skin-select li span {
  position: relative;
  left: 10px;
}

.menu-hide span {
  color: red;
}

#skin-select li ul li {
  line-height: 20px;
}

#skin-select li i {
  width: 20px;
  font-size: 18px;
  font-style: normal;
  line-height: 15px;
  position: relative;
  top: 5px;
  left: -5px;
}

#skin-select li li i {
  width: 23px;
  font-size: 18px;
  font-style: normal;
  line-height: 15px;
  display: inline-block;
  position: relative;
  top: 2px;
  left: -5px;
}

.skin-part {
  padding: 0;
  position: relative;
  top: 0;
}

.skin-part h3 {
  color: #000;
  text-shadow: none;
  margin-bottom: 10px;
}

.skin-part h3 small {
  color: #999;
  margin-left: 6px;
}

.skin-part h4 {
  font-size: 1em;
  margin: 4px 0 !important;
}

.title-leftmenu {
  padding: 10px;
}

.title-menu-left {
  width: 100%;
  line-height: 25px;
  text-decoration: none;
  list-style: none;
  display: block;
  color: #ffffff80 !important;
  text-transform: uppercase !important;
  padding: 5px 10px 5px 0 !important;
  font-size: 10px !important;
}

.title-menu-left:hover {
  background: none !important;
}

.title-menu-left:hover:after {
  border-left: 4px solid #0000 !important;
  display: none !important;
}

.title-menu-left span {
  height: 22px;
  background: #0003;
  border-radius: 20px;
  padding: 0 11px;
  line-height: 20px;
}

.config-wrap {
  margin: 0 -12px 0 0;
}

.config-wrap[title]:hover:after {
  font-size: 9px !important;
}

.widget-menu:before {
  content: "Widget Menu" !important;
}

.design-kit:before {
  content: "Design Kit Menu" !important;
}

.component:before {
  content: "Component Kit Menu" !important;
}

/*# sourceMappingURL=index.be964e03.css.map */
